@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .provider-profile-div {
    @apply bg-white py-6 px-6 shadow-provider-proifle rounded-lg;
  }

  .box {
    @apply border border-solid border-black;
  }
  .text-bg {
    background-image: url('../public/assets/landingPage/shape-yellow.svg');
    padding: 0px 40px 5px 0px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;

    /* padding: 1rem */
  }
  .featured-text-bg {
    background-image: url('../public/assets/landingPage/shape-blue.svg');
    padding: 2rem 40px 10px 0px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    width: 299px;
    height: 62px;
    left: -100px;
    top: -40px;
    position: absolute;
    z-index: 0;
  }
  .partner-bg {
    background-image: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.7722) 10.3%,
        rgba(217, 217, 217, 0) 115.51%
      ),
      url('../public/assets/landingPage/Truck_Image.jpg');
    background-repeat: no-repeat;

    padding: 54px;
  }

  .Reviews-text-bg {
    background-image: url('../public/assets/landingPage/shape-dark-yellow.svg');
    padding: 2rem 40px 10px 0px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    width: 299px;
    height: 62px;
    right: 0;
    top: -40px;
    position: absolute;
    z-index: 0;
  }

  .textunderline {
    text-decoration: none;
    cursor: pointer;
    font-size: 14px !important;
    z-index: 0;
  }

  .hover-underline-animation {
    display: inline-block;
    position: relative;
    color: black;
    font-weight: 500;
  }

  .hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: black;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  .hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  @keyframes filterValue {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @keyframes filterlabel {
    100% {
      transform: translateY(-5);
    }
  }
  @keyframes VibrateText {
    0% {
      -webkit-transform: translate(2px, 1px) rotate(0deg);
    }
    10% {
      -webkit-transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
      -webkit-transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
      -webkit-transform: translate(0px, 2px) rotate(0deg);
    }
    40% {
      -webkit-transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
      -webkit-transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
      -webkit-transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
      -webkit-transform: translate(2px, 1px) rotate(-1deg);
    }
    80% {
      -webkit-transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
      -webkit-transform: translate(2px, 2px) rotate(0deg);
    }
    100% {
      -webkit-transform: translate(1px, -2px) rotate(-1deg);
    }
  }
  .animateFilterValue {
    animation-name: filterValue;
    animation-duration: 1s;
  }
  .animateErrorValue {
    animation-name: filterValue, VibrateText;
    animation-duration: 0.2s;
  }
  .animateFilterLabel {
    animation-name: filterlabel;
    animation-duration: 1s;
  }
  .active-btn {
    @apply bg-secondary-50  text-white   border-secondary-50 border-2 border-solid py-2 px-4 w-[100px] font-semibold;
  }
  .inactive-btn {
    @apply bg-white text-secondary-50   border-secondary-50 border-2 border-solid py-2 px-4 w-[100px] font-semibold;
  }
  .active-btn-Home-Hero {
    @apply bg-black text-white   border-black border-2 border-solid py-2 px-4 w-[160px] min-h-[48px];
  }
  .inactive-btn-Home-Hero {
    @apply bg-white text-black   border-black border-2 border-solid py-2 px-4  w-[160px] min-h-[48px];
  }
  .error {
    @apply text-red-500 !text-[12px];
  }
  .active_tab {
    @apply bg-white text-black;
  }
  .inactive_tab {
    @apply border-solid border-white border-2 bg-transparent;
  }
  .hero {
    background-image: url(../public/assets/landingPage/Header_bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1000;
  }
  .searchInventory {
    /* background:linear-gradient(90deg, #000000 59.7%, rgba(0, 0, 0, 0) 77.19%); */

    border: 1px solid primary-50;
  }
  .Hero-tags {
    background-color: rgba(255, 255, 255, 0.233);
  }
  .Scale_Child > * {
    transform: scale(1.125);
  }
  .my-facebook-button-class {
    @apply bg-white border-0 cursor-pointer justify-center font-medium text-gray-80;
  }
  .CustomDropDownListItem {
    @apply border-[1px] cursor-pointer  text-center !mt-2 border-solid rounded-sm border-gray-20;
  }
  .CustomDropDownListItem:hover {
    @apply border-[1px] cursor-pointer  text-center !mt-2 border-solid rounded-sm border-primary-30 bg-primary-5;
  }
}

::-webkit-scrollbar {
  width: 0px;
  scrollbar-width: thin;
  height: 1px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  width: 0px;
  scrollbar-width: thin;
  height: 3px;
}

::-webkit-scrollbar-thumb {
  /* background-color: #d6dee1; */

  border-radius: 20px;
  width: 0px;
  background-clip: content-box;
  height: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
  width: 0px;
  height: 3px;
}

::-webkit-scrollbar-thumb:horizontal {
  scrollbar-width: thin;
}

.textCaps {
  text-transform: capitalize;
}

/* mobile css */
@media screen and (min-width: 300px) and (max-width: 372px) {
  .swaperMb {
    height: 250px !important;
    width: 300px !important;
    position: relative;
    transform: translate(-15%, 0px);
  }
  .formimg {
    object-fit: cover;
    width: 320px !important;
    height: auto !important;
  }
}

@media screen and (min-width: 373px) and (max-width: 424px) {
  .swaperMb {
    position: relative;
    transform: translate(-8%, 0px);
  }
  .formimg {
    object-fit: cover;
    width: 350px !important;
    height: auto !important;
  }
}
@media screen and (min-width: 425px) and (max-width: 767px) {
  .formimg {
    object-fit: cover;
    width: 390px !important;
    height: auto !important;
  }
}
/* mb tb css */
@media screen and (max-width: 767px) {
  .truckCrdMb {
    display: inline-block;
    margin-top: 85px;
  }
  .trcklctWd {
    width: 50%;
  }
  .trcklctaln {
    align-items: baseline;
  }
  .formMb {
    padding: 0;
  }
  .fieldMb {
    width: 100%;
    padding-bottom: 15px;
  }
  .formBtn {
    width: 100% !important;
  }
  .formpd {
    padding: 0 8px !important;
  }
  .aboutPd{
    padding: 24px 5px !important;
  }
  .discPd{
    padding-left:0px ;
    padding-right: 0px;
  }
  .discPdA{
    padding: 0;
  }
  .homeAln{
    justify-content: left;
  }
  .JoinLine{
    display: none !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .truckCrdMb {
    display: inline-block;
    margin-top: 85px;
  }
  .formMb {
    padding: 24 0;
  }
  .formimg {
    height: auto !important;
    width: 355px !important;
  }
  .swaperMb {
    height: 330px !important;
    width: 750px !important;
  }
  .SwiperImgTb{
    object-fit: fill;
    height: 330px;
  }
  .miniImg img{
    height: auto;
  }
}
@media screen and (min-width: 800px) and (max-width: 850px) {
  .truckCrdMb {
    display: inline-block;
    margin-top: 120px;
  }
  .abutImg{
    height: auto !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .truckCrdMb {
    display: inline-block;
    margin-top: 85px;
  }
  .swaperMb {
    height: 425px !important;
    width: 970px !important;
  }
  .SwiperImgTb {
    object-fit: fill;
    height: 400px;
  }
  .miniImg img {
    height: auto;
  }
}
@media screen and (min-width: 1110px) and (max-width: 1130px) {
  .truckCrdMb {
    display: inline-block;
    margin-top: 120px;
  }
}

