.custom-stepper .MuiStepConnector-line{
    min-height: 45px !important;
}

.stepper-content-header h3 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #051C2C;
}

.stepper-label-wrapper {
    position: relative;
}

.stepper-label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
}

.stepper-description-completed {
    position: absolute;
    left: 0px;
    top: 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #BBDBF0;
    display: flex;
    align-items: center;
    gap: 5px;
}