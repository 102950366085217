.extendBtnStyle {
  /* width : 250px; */
  height: 39px;
  border-radius: 100px !important;
  background-color: #0f5e91 !important;
  color: #ffffff !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.inspectionFeeBtnStyle {
  /* width : 250px; */
  height: 39px;
  border-radius: 100px !important;
  background-color: #332700 !important;
  color: #ffc200 !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.waitingBtnStyle {
  /* width : 250px; */
  height: 39px;
  border-radius: 100px !important;
  background-color: #ffc200 !important;
  color: #4d3a00 !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.deniedBtnStyle {
  /* width : 250px; */
  height: 39px;
  border-radius: 100px !important;
  background-color: #fdf3f2 !important;
  color: #dc2804 !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.quoteSentBtnStyle {
  /* width : 250px; */
  height: 39px;
  border-radius: 100px !important;
  background-color: #fff9e5 !important;
  color: #997400 !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.completeBtnStyle {
  /* width : 250px; */
  height: 39px;
  border-radius: 100px !important;
  background-color: #edf7f2 !important;
  color: #05945b !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.normalextbtnStyle {
  height: 39px;
  border-radius: 100px !important;
  background-color: #e3e3e3 !important;
  color: #333333 !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}
.normalButton {
  /* width: 120px !important; */
  height: 42px !important;
  border-radius: 5px !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  background-color: #0c4b74 !important;
  color: #ffffff !important;
}
.normalTextBtn {
  width: 180px !important;
  height: 44px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.detailsContainer-button-style {
  display: flex;
  justify-content: center;
  width: 180px;
  height: 45px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 6px;
}
.fontStyle {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #333333;
}
.btnStyle {
  color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  background-color: #d92d20;
  border-radius: 12px;
}
.btnCancelStyle {
  color: #333333;
  font-weight: 600;
  font-size: 16px;
  border-radius: 12px;
}

.EquipmentInfo-grid {
  /* width:1240px; */
  /* min-height: 190px;  */

  /* margin:16px 12px 16px 12px; */
  padding-top: 10px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08);
}
.table-grid {
  display: grid;
  flex-direction: row;
  height: 40px;
  background-color: #f5f5f5;
  /* grid-template: 20% 20% 20% 20%; */
  grid-template-columns: 30% 30% 30%;
  column-gap: 40px;
  border-radius: 10px;
  padding-bottom: 10px;
}
.tableGrid-child {
  display: grid !important;
  grid-template-columns: 50% 50% !important;
  column-gap: 40px !important;
  height: 40px;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
}
.tableGrid-child-text {
  /* margin-left: 20px; */
  display: flex;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 5px;
  /* cursor: pointer; */
  width: 73px;
}
.border-style {
  border: 1px solid;
  border-bottom-color: #0f5e91;
  border-top-color: #ffffff;
  border-left-color: #ffffff;
  border-right-color: #ffffff;
}

.product-detail-pageBtn {
  width: 250px;
  height: 40px;
  font-size: 14px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .border-style {
    margin-left: 0 !important;
    height: 41px;
    width: 50%;
    border: 0;
    text-decoration: underline;
    text-decoration-color: #0f5e91;
    text-underline-offset: 6px;
    justify-content: flex-start;
    padding-right: 15px;
    padding-left: 15px;
  }
  .quote {
    width: 220px !important;
  }
  .containerSt {
    justify-content: center !important;
  }
  .SliderScontent {
    justify-content: center;
  }
}
@media screen and (max-width: 767px) {
  .quote {
    width: 250px !important;
  }
  .containerSt {
    justify-content: center !important;
  }
  .border-style {
    margin-left: 15px !important;
  }
}
@media screen and (min-width: 1024px) and (max-width: 2000px) {
  .containerSt {
    /* justify-content: center !important; */
    padding-top: 0 !important;
  }
  .respLtp {
    padding: 15px 0px 15px;
    z-index: 990;
    width: 100%;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #ffffff;
  }
  .EquipmentInfo-grid {
    margin-bottom: 60px;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1279px) {
  .respLtp {
    padding-right: 1%;
  }
  .sliderCnt {
    margin-left: 10px !important;
  }
}
@media screen and (min-width: 1500px) {
  .tableGrid-child-text {
    width: 75px;
  }
}
/* mobile css */
@media screen and (min-width: 300px) and (max-width: 372px) {
  .tablemb {
    padding-right: 100px !important;
  }
  .SliderDiv {
    max-width: 295px;
  }
}
@media screen and (min-width: 354px) and (max-width: 372px) {
  .SliderDiv {
    max-width: 345px;
  }
  .tablemb {
    width: 320px;
    padding-right: 45px !important;
  }
}
@media screen and (min-width: 373px) and (max-width: 424px) {
  .tablemb {
    padding-right: 30px !important;
    width: 355px;
  }
  .SliderDiv {
    max-width: 362px;
  }
}
@media screen and (min-width: 425px) and (max-width: 550px) {
  .tablemb {
    padding-right: 10px !important;
  }
  .SliderDiv {
    max-width: 385px;
  }
}

@media screen and (max-width: 767px) {
  .tableGrid-child {
    display: grid !important;
    grid-template-columns: 50% 50% !important;
    column-gap: 0px !important;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    padding: 0 10px;
    width: 100%;
    word-break: break-word;
  }

  .stockdiv {
    gap: 10px;
  }
  .sliderCnt {
    margin-left: 10px !important;
  }
}
