.stepper-content.TruckCategory {
    display: grid;
    grid-template-columns: repeat(3, 33.33%);
    gap: 20px;
    width: calc(100% - 40px);
}

.category {
    display: flex;
    flex-direction: column;
    padding: 15px;
    gap: 15px;
    background: #F5F5F5;
    border-radius: 8px;
}

.category-heading {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #051C2C;
}

.truck {
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    border: 1px solid transparent;

    width: calc(100% - 30px);
    height: 72px;
    padding: 0px 15px;
    display: grid;
    grid-template-columns: repeat(2, 50%);
    align-items: center;
    /*display: flex;*/
    /*flex-direction: row;*/
    /*justify-content: center;*/
    /*padding: 0px;*/
    /*gap: 24px;*/

    /*max-width: 260px;*/
    /*width: 100%;*/
    /*height: 100px;*/

    /* Text font/16/medium */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #545454;
    transition: border-color 0.3s ease-in;
}

.truck.active {
    border: 1px solid #FFC200;
    background: #FFFFFF;
    color: #545454;
    box-shadow: none !important;
}

.truck .img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.truck .img {
    /*max-width: 140px;*/
    max-width: 86px;
    user-select: none;
}

.truck .name {
    display: flex;
    justify-content: center;
    text-align: center;
}


.action-bar .actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.action-bar .actions button {
    width: calc(33% - 30px);
    height: 44px;
}