.video-container:hover .video-delete-button svg path {
    /*fill: #333333;*/
    fill: #ff0000;
}

.video-delete-button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 4px;
}

.video-delete-button:hover {
    background-color: red;
}
.video-delete-button:hover span {
    padding-right: 2px;
    width: 42px;
    transition: width 0.15s linear;
}
.video-delete-button:hover svg path {
    fill: #FFFFFF !important;
}

.video-delete-button span{
    padding-right: 0px;
    font-size: 12px;
    overflow: hidden;
    width: 0px;
}

.video-delete-button svg {
    width: 10px;
}

.video-delete-button svg path {
    fill: #858585;
}


