/* Container to hold the entire component layout */
.privacy-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 95vh;
    width: 100vw;
    overflow: hidden;
  }
  
  /* Header styles - fixed at the top */
  .privacy-header {
    width: 100%;
    max-width: 800px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
    padding-bottom: 16px;
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Scrollable content section */
  .privacy-content {
    width: 100%;
    max-width: 800px;
    overflow-y: auto;
    flex-grow: 1;
    padding: 16px;
  }
  
  /* List styles */
  .privacy-content ul {
    padding-left: 24px;
  }
  
  .privacy-content ul li {
    margin-bottom: 8px;
  }
