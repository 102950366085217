.ask {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #333333;
}

.clickToUpload {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #262626;
}

.upload-area.active {
  border: 3px solid #FFC200;
  background-color: transparent;
}

.upload-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 48px; */
  gap: 15px;
  width: 464px;
  height: 136px;
  /* height: 208px; */
  /* Primary/50 */
  border: 3px dashed #FFC200;
  border-radius: 12px;
  cursor: pointer;
  user-select: none;
  transition: transform 0.3s ease-in;
}

.upload-area.active .ua-content{
  width: calc(100% - 30px);
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px;
}
.upload-area.active .ua-content .file-info{
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  width: 100%;
  align-items: center;
  gap: 15px;
}
.upload-area.active .ua-content .file-info .icon{
  display: flex;
  align-items: center;
  justify-content: center;
}
.upload-area.active .ua-content .file-info .label {
  /* Body/Regular */
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #757575;
}
.upload-area.active .ua-content .file-info .file-size {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #333333;
}
.upload-area .ua-content{
  transition: transform 0.3s ease-in;
}
.upload-area.in-active:hover{
  transform: scale(0.995);
}
.upload-area.in-active:hover .ua-content{
  transform: translateY(-5px);
}
.files-grid {
  display: grid;
  grid-template-columns: repeat(2, 50%);
  width: max-content;
  gap: 24px;
}

.RepairHistoryPage .upload-area {
  max-width: 464px !important;
  min-width: 390px !important;
  width: 100% !important;
}