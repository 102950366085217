.App {
  text-align: left;
  width: 100%;
}

/* body {
  font-family: 'Poppins', sans-serif !important;
} */
body{
  font-family: Inter , Space Grotesk   ;
}

.font-Inter {
  font-family: Inter !important;
}

.space_grotesk{
  font-family: Space Grotesk !important;
}
img {
  width: 100%;
}
.z_index_adjustment{
  z-index: 9;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Space Grotesk !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
/* .active {
  color: white;
  background-color: #00c657;
}
.inactive {
  color: black;
  background-color: white;
} */
button:disabled {
  opacity: 0.3;
}
.default-checkbox:checked {
  background-color: #014f86;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.scrollable-div::-webkit-scrollbar {
  display: initial !important;
}

.scrollable-div {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.scrollable-div::-webkit-scrollbar {
  width: 6px;
  scrollbar-width: thin;
  height: 1px;
}

.scrollable-div::-webkit-scrollbar-track {
  background-color: transparent;
  width: 6px;
  scrollbar-width: thin;
  height: 3px;
}

.scrollable-div::-webkit-scrollbar-thumb {
  background-color: #FFC200;
  border-radius: 20px;
  width: 6px;
  background-clip: content-box;
  height: 3px;
}

.scrollable-div::-webkit-scrollbar-thumb:hover {
  background-color: #FFC200;
  width: 6px;
  height: 3px;
}

.scrollable-div::-webkit-scrollbar-thumb:horizontal {
  scrollbar-width: thin;
}




input:-internal-autofill-selected {
  background-color: transparent;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body {
  margin: 0px;
}
.loginBoxShadow {
  background: #ffffff;
  /* shadow/md */

  box-shadow: 0px 4px 8px -2px rgba(16, 40, 36, 0.1),
    0px 2px 4px -2px rgba(16, 40, 36, 0.06);
}
.searchBoxShadow {
  box-shadow: 0px 1px 3px rgba(16, 40, 36, 0.1),
    0px 1px 2px rgba(16, 40, 36, 0.06);
}

.tableBoxShdow {
  background: #ffffff;
  /* shadow/md */

  box-shadow: 0px 4px 8px -2px rgba(16, 40, 36, 0.1),
    0px 2px 4px -2px rgba(16, 40, 36, 0.06);
  border-radius: 8px;
}
.header {
  grid-area: header;
  background: #ffffff;
  /* gray/300 */

  border-bottom: 1px solid #d1d5db;
}
.header-1 {
  grid-area: header;
  background: #ffffff;
}
.sidebar {
  grid-area: sidebar;
  background-color: #fff;
  border-right: 1px solid #e5e7eb;
  margin-top: 57px !important;
}
.content {
  grid-area: content;
  overflow-y: scroll;
}
.footer{
  grid-area: content;
}
.footer{
  grid-area: content;
}
.g-container {
  overflow-x: hidden;
  height: 100vh;
  grid-template-rows: 70px auto;
  grid-template-columns: 250px auto;
  background-color: #fcfcfd;

  grid-template-areas:
    'header  header '
    'sidebar content '
     'footer footer';
}
.g-container_Nav_Footer{
  overflow-x: hidden;
  height: 100vh;
  background-color: #fcfcfd;

  grid-template-areas:
    'header  header '
    'content content ';
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-internal-autofill-previewed,
input:-internal-autofill-selected,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.pac-container {
  z-index: 1500;
  border: 0;
  padding-top: 10px;
  box-shadow: 0px 0px 0px 0px !important;
  -webkit-box-shadow: 0px 0px 0px 0px !important;
}
.pac-item {
  padding-left: 10px;
  border: 0;
}

.no-border-textfield > div:before,
div:after {
  @apply !border-b-0 !outline-none
  /* border-bottom: none !important;
  outline: none !important; */
}


/* Global Util */

/*or section*/

.or-section {
  display: flex;
  justify-content: center;
  position: relative;
  height: 1px;
  background-color: #F5F5F5;
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #B0B0B0;
  padding: 0px 5px;
  margin: 15px 0;
}

.or-section::before {
  content: 'Or';
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  padding: 0 15px;
  background-color: #FFFFFF;
}

/* Overrides */

.modalButton:hover {
  text-transform: initial;
  /*color: black !important;*/
  /*background-color: #FFC200 !important;*/
  border-color: transparent !important;
}

.stepper-content-header h3{
  font-family: Inter !Important;
}
.saveCCard{
  height: 43px;
  width: 265px;
}
.swiper-pagination-bullets-dynamic{
  position: inherit;
  padding-top: 45px; 
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active { 
  background: #06263A !important;  
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet{
  background: rgba(6, 38, 58, 1);
  width:12px;
  height: 12px;
}
.swmt-45{
  margin-top: -45px;
}
.topSearch .MuiAutocomplete-inputRoot{
  height: 44px;
  padding-right: 10px !important;
}
.topSearch .MuiInputBase-input {
  padding: 0px !important;
}
.emiCalLable { 
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -1px;
  color: #000000;
}
.emiCalResLable{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -1px;
  color: #545454;
}
.emiCalRes{
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 120%;
  letter-spacing: -1px;
  color: #000000;
  min-height: 27px;
}

.footerC{
  float: left;
  display: flex;
}
.footerCant{
  float: left;
  width: 100%;
  max-width: 1600px;
  display: block;  
  margin: auto;
  padding: 0 30px;
  padding-bottom: 20px;
  
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}
.footerLogoCant{
  width: 28%;
  float: left;
}
.footerMenuCant{
  width: 19%;
  float: left;
}
.footerMfCant{
  width: 13%;
  float: left;
}
@media only screen and (min-width: 700px) and (max-width: 900px){
  .footerMfCant{
    width: 16%;
  }
  .footerMenuCant{
    width: 18%;
  }
  .text-s{
    padding-top: 10px;
  }
  .t-gap{
    gap: 0;
  }
  .t-margen{
    margin: auto !important;
  }
  .t-pad{
    padding: 1.25rem;
  }
  .t-padl{
    padding-left: 1.25rem !important;
  }
  .t-align{
    align-items: center;
  }
  .t-top{
    padding-top: 40px;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1000px){
  .t-pad{
    padding: 1.25rem;
  }
  .t-padl{
    padding-left: 1.25rem !important;
  }
  .t-align{
    align-items: center;
  }
  .t-top{
    padding-top: 40px;
  }
}
@media only screen and (min-width: 900px) and (max-width: 1280px){
  .t-margen{
    margin: auto !important;
  }
}

.footerLogoCantSmall{
  width: 100%;
  float: left;
}
.footerMenuCantSmall{
  width: 50%;
  float: left;
}
.footerMfCantSmall{
  width: 50%;
  float: left;
}

.footMenuH{
  font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 20px;
color: #EDEDED;
}
.footMenu{
  font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
color: #FFFFFF;
}
.footCopy{
  font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
color: #FFFFFF;
}
.footMenuC{
  text-align: right;
  display: block ruby;
  padding-right: 0;
}
.gap-45{
  gap: 12rem;
}
.shipmentText{  
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 120%;
  text-align: center;
  color: #051C2C;
}
.imgListinDiv{
  display: block;
  background-color: #f2f2f2;
border: 1px solid #e6e6e6;
border-radius: 8px;
float: left;
height: 150px;
overflow: hidden;
position: relative;
width: 100%;
margin-bottom: 10px;
cursor: pointer;
}
.listImg{
  max-width: 100%;
  max-height: 150px;
  min-height: 150px;
  min-width: 100%;
  object-fit: contain;
}
.paynowBtn{
  width: 265px;
  height: 44px;
}

/* Notifications Custom Component */
.notifications-container-film {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.15);
  z-index: 1;
}
.notifications-container {
  position: absolute;
  top: calc(100% + 13px);
  right: 0px;
  background: white;
  border-radius: 6px;
  box-shadow: 0 0 8px rgba(0,0,0,0.3);
  height: 320px;
  min-width: 320px;
  z-index: 2;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 30px;
}

.notification-header{
  position: sticky;
  top: 0px;
  background-color: #FFFFFF;
  padding: 10px;
  font-weight: 500;
  font-family: Inter !important;
}

.notification-item {
  display: grid;
  grid-template-columns: 1fr ;
  padding: 10px;
  margin: 0px 10px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f4f4f4;
}

.notification-item:hover {
  /*box-shadow: 0 0 4px rgba(0,0,0,0.2);*/
  /*background-color: #f3f3f3;*/
  background-color: #FFC200;
}

.notification-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.notification-content .title {
  display: flex;
  color: #172B4D;
  font-size: 14px;
  font-weight: 500;
  justify-content: space-between;
}
.notification-content .title > .time{
  font-size: 10px;
}


.notification-content .body {
    color: #172B4D;
    font-size: 12px;
    font-weight: 400;
}
video::-webkit-media-controls-overlay-play-button {
  display: none;
}


.ant-image-preview-switch-left  {
  color: white !important;
  background:gray !important
}


.ant-image-preview-switch-right  {
  color: white !important;
  background:gray !important
}


.ant-image-preview-img {
width: auto !important;
}

