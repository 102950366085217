.BasicInformationPS .form-item{
    width: calc(100% / 3 - 24px);
    display: flex;
    flex-direction: column;
}

.form-item select {
    height: 44px;
    padding: 14.5px 14px;
    background-color: #fff!important;
    border-radius: 8px;
    border-color: #000000;
}

.form-item > select {
    padding: 0px 14px !important;
}

@media screen and (max-width: 1200px) {
    .BasicInformationPS .form-item{
        width: calc(100% / 2 - 24px);
    }
    .form-item select {
        height: 44px;
        padding: 14.5px 14px;
    }
    .form-item > select {
        padding: 0px 14px !important;
    }
    .optional-form-item-group {
        width: calc(50% * 2 - 24px);
    }
    .whitespace-container {
        display: none !important;
    }
}


@media screen and (max-width: 960px) {
    .BasicInformationPS .form-item{
        width: 100% !important;
        display: flex;
        flex-direction: column;
    }
}

.currency-container {
    position: relative;
}
.currency-container .currency-label {
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    width: 44px;
    height: 44px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    z-index: 111;
    border-right: 1px solid #c4c4c4;;
}

.currency-container input {
    padding-left: 60px;
}

.BasicInformationPS .form-item input {
    height: 44px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.BasicInformationPS label {
    font-size: 18px !important;
    color: #545454 !important;
    font-weight: 500 !important;
}
.BasicInformationPS .form-item > div > label {
    height: auto;
    width: auto;
    font-size: 24px !important;
    line-height: 21px !important;
}

.BasicInformationPS .MuiOutlinedInput-notchedOutline {
    /*border-color: #000000 !important;*/
}

.BasicInformationPS select:focus {
    border-color: #FFC200 !important;
    outline-color: #FFC200 !important;
}

.BasicInformationPS .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #FFC200 !important;
}


.BasicInformationPS .CustomTextField_label {
    display: none;
}

.BasicInformationPS .currency-container .CustomTextField_label {
    display: inherit;
}

.BasicInformationPS select {
    font-size: 16px;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
    background-position: 100%;
    background-repeat: no-repeat;
    width: 100%;
    border: 1px solid;
}

.BasicInformationPS input {
    color: #545454;
}

.BasicInformationPS input::placeholder, .BasicInformationPS select::placeholder {
  color: #757575;
  opacity: 1; /* Firefox */
}