.profile-container {
    display: flex;
    height: 100%;
    /* border : 1px solid black; */
    box-shadow: 1px 0px 0px 0px #ffff;
    margin: 5px;

}

.item-container-div {
    display: grid;
    grid-template-columns: 30% 63%;
    column-gap: 45px;
    margin-top: -70px;
    margin-left: 45px;
}

.primary-theme-div {
    width: 100%;
    height: 200px;
    background-color: #0F5E91;
}

.div-border-Style {
    background-color: #ffffff;
    /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05); */
    height: 310px;
    border-radius: 8px;
}
.profile-first-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
    /* border: 1px solid black; */
    height: 330px;
    width: 180px;
    /* margin: 5px; */
    justify-content: center;
    align-items: center;
    /* padding-top: 10px */
    
}
.profile-img-container{
    display: flex;
    width: 155px;
    height: 155px; 
    position: relative;
    border: 3px solid #ffffff;
    filter: drop-shadow(0px 2px 6px rgba(0,0,0,0.08));
    justify-content: center;
}

.container-grid-div {
    height: 220px;
    /* border: 1px solid black; */
    align-items: flex-start;
    /* gap: 24px  */
}

.button-style {
    display: flex;
    justify-content: center;
    width: 114px;
    height: 32px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 6px;
}
.detailsContainer-button-style {
    display: flex;
    justify-content: center;
    width: 180px;
    height: 45px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 6px;
}
.detailsContainer-button-div{
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin: 10px;
    justify-content: flex-end;
    padding-top: 45px;
    padding-right: 8px;
}
.container-grid {
    justify-content: center;
    margin: 5px;
    padding-top: 20px;
    gap: 10px
}

.email-container {
    justify-content: center;
    margin: 5px;
    /* padding-top: 20px; */
    column-gap: 25px
}

.email-fields {
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08)
}

.profile-name-field-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.profile-name {
    font-weight: 500;
    font-size: 20px;
    color: #262626;
}

.display-flex {
    display: flex;
    flex-direction: column;
}

.profile-fields {
    width: 370px;
    height: 45px;
    /* border:1px solid black; */
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08)
}

.profile-name-field {
    margin-left: -15px;
    font-size: 14px;
    font-weight: 500;
    color: #545454;
}
.cameraIcon-container {
    display: flex; 
    position: absolute;
    /* padding-top: 110px; */
    width: 36px;
    height: 36px;
    /* padding-left: 116px; */
    justify-content: center;
    /* border: 1px solid black; */
}
.camera-icon-div{
    display: flex;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 22px;
    background-color: #ffffff;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.05);
    /* /* padding-top: 30px; */
}