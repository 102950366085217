.ag-grid {
    height: calc(100vh - 97px);
    display: grid;
    grid-template-rows: auto 1fr auto;
}

.main-wrapper {
    /*border: 1px solid #000;*/
}

.steps-wrapper {
    background-color: #0F5E91;
    /*width: 380px;*/
    /*border: 1px solid #f00;*/
}

.content-wrapper {
    /*padding: 32px;*/
    /*border: 1px solid #f00;*/
    width: calc(100% - 300px);
    padding: 0 32px;
}

.optional-form-item-group {
    width: calc(33.33% * 2 - 24px);
    display: flex;
    justify-content: space-between;
    gap: 48px;
}

.optional-form-item-group > div {
    position: relative;
}

.BasicInformationPS .optional-form-item-group > div:nth-child(1)::after{
    position: absolute;
    content: "Or";
    right: -34px;
    bottom: 12px;
    color: #333333;
}

.optional-form-item-group  .form-item{
    /* width: calc(50% - 24px) !important; */
    position: relative;
    width: calc(50% - 12px) !important;
    display: flex;
    flex-direction: column;
}
.form-item {
    position: relative;
    margin-bottom: 15px;
}
.form-item .error{
    position: absolute;
    bottom: -22px;
    left: 4px;
}

.form-item select{
    border-color: rgba(145,145,145,0.7) !important;
}
/* Custom Rules */
.stepper-icon {
    width: 24px;
    height: 24px;
    border-radius:12px;
    background-color: #F2F4F7;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
}
.stepper-icon .icon {
    background-color: #ffffff;
    width: 8px;
    height: 8px;
    border-radius:4px;
}

.stepper-icon.active {
    background-color: #FFFFFF;
}
.stepper-icon.active .icon {
    background-color: #FFCE33;
}


/* For Children Components*/


.content-footer {
    margin-top: 30px;
    margin-bottom: 30px;
}


.outter-most-wrapper {
    background-image: linear-gradient(to right, #0F5E91 50%, #ffffff 50%);
}


@media screen and (max-width: 600px) {

    .upload-area {
        max-width: 464px;
        width: 100% !important;
    }

    .outter-most-wrapper {
        background-image: none !important;
        background-color: #ffffff !important;
    }
    .main-wrapper{
        flex-direction: column;
    }

    .steps-wrapper {
        width: 100%;
    }

    .content-wrapper {
        width: 100%;
        padding: 0px;
    }

    .stepper-content-header {
        /*flex-direction: column;
        gap: 30px;
        justify-content: flex-start;*/
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 14px;
    }
    .stepper-content-header div {
        width: 100% !important;
    }
    .stepper-content-header button {
        width: 100% !important;
    }

    .stepper-content {
        padding: 0px 5px;
    }

    .stepper-content textarea {
        width: calc(100% - 20px);
    }

    /* Step 1 - Truck Selection */
    .TruckCategoryPage.action-bar {
        display: none;
    }

    .TruckCategory.stepper-content{
        display: grid;
        grid-template-columns: repeat(1, 100%) !important;
        gap: 20px;
        width: 100% !important;
    }
    
    /* Step 3 - Basic Information */
    .optional-form-item-group {
        width: 100%;
        display: flex;
    }
    .BasicInformationPS .form-item{
        width: 100% !important;
        display: flex;
        flex-direction: column;
    }

    .stepper-content-actions-footer {
        flex-direction: column !important;
        margin-bottom: 30px;
    }
    .content-footer button{
        width: 100% !important;
    }

    /* Step 4 - Upload Images */
    .image-selector-wrapper {
        position: relative;
        width: calc(50% - 25px) !important;
        height: 150px;
        border-radius: 8px;
    }

    /* Step 8 - Repair History */
    .files-grid {
        display: grid;
        grid-template-columns: repeat(1, 100%) !important;
        width: 100% !important;
        gap: 24px;
    }
    .files-grid .upload-area {
        max-width: 464px !important;
        width: 100% !important;
    }

    .MuiSelect-outlined{
        padding-left: 0px !important;
        padding-top: 5px !important;
        padding-bottom: 9px !important;
    }

    .optional-form-item-group {
        width: 100% !important;
        display: flex;
        flex-direction: column !important;;
        justify-content: space-between;
        gap: 48px;
    }
    
    .optional-form-item-group > div {
        position: relative;
    }
    
    .optional-form-item-group > div:nth-child(1)::after{
        position: absolute;
        content: "Or";
        left: 0px;
        bottom: -36px;
    }
    
    .optional-form-item-group  .form-item{
        /* width: calc(50% - 24px) !important; */
        width: 100% !important;
        display: flex;
        flex-direction: column;
    }

    /* stepper.jsx */
    .mobile-label {
        display: flex !important;
        color: #ffffff!important;
        justify-content: center;
    }
    .custom-stepper-container {
        max-width: 600px !important;
        padding: 20px !important;
    }
    .stepper-label-wrapper {
        display: none !important;
    }
    .MuiStepConnector-root {
        flex: 1 1 auto;
        position: absolute;
        top: 12px;
        left: calc(-76% + 20px);
        right: calc(76% + 20px);
        width: 50%;
    }
    .MuiStepper-root {
        margin-left: -8px;
    }
}


/*Buttons*/
.full-rounded-btn {
    border-radius: 22px !important;
}

/* Form Item Overrides */
.outter-most-wrapper .form-item label {
    color: #545454;
}
.outter-most-wrapper .MuiInputBase-root {
    color: #919191;
}

.file-preview-img-container {
    width: 100%;
    max-height: 480px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.file-preview-img-container img {
    max-width: 100%;
    max-height: 480px;
    width: auto;
}

.videoDisabled {
    cursor: progress;
    pointer-events: none;
    opacity: 0.4;
}
