.image-selector-wrapper {
    position: relative;
    width: calc(25% - 25px);
    height: 150px;
    border-radius: 8px;
}
.image-selector-wrapper .removeAction {
    cursor: pointer;
    position: absolute;
    top: -5px;
    right: 0px;
}

.image-selector-wrapper .checkbox-container {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    gap: 5px;
    /* Typography */
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    color: #FFFFFF;
}
.image-selector-wrapper .checkbox-container .un-checked{
    width: 15px;
    height: 15px;
    cursor: pointer;
    border-radius: 4px;
    border: 2px solid #FFFFFF;
    background-color: transparent;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.05);
}
.image-selector-wrapper .checkbox-container span{
    padding: 4px;
    border-radius: 4px;
    background-color: #0F5E91;
}
.image-selector-wrapper .checkbox-container svg{
    width: 20px;
    height: 20px;
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.05);
}

.image-selector-wrapper:hover {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
}

.image-selector-wrapper .image-selector-box {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px dashed #EDEDED;
    border-radius: 8px;
    width: 100%;
    height: 100%;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #545454;
}

.image-selector-wrapper .image-selector-box .label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #545454;
}

.image-selector-wrapper .image-selector-box .icon {
    width: 40px;
    height: 40px;
}
.image-selector-wrapper .image-selector-box img {
    width: 100%;
    height: 100%;
}
