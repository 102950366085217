.FileDocumentDetailWrapper {
    padding: 10px;
    min-height: 80px;
    min-width: fit-content;
    /*max-width: 240px;*/
    background: #FFFFFF;
    border: 1px solid #BBDBF0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    display: grid;
    grid-template-columns: 40px 1fr 20px;
}
.FileDocumentDetailWrapper.dashed {
    border: 1px dashed #BBDBF0;
    grid-template-columns: 1fr;
    cursor: pointer;
}

.FileDocumentDetailWrapper.dashed .upload-new{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}


.FileDocumentDetailWrapper .content-area {

}
.FileDocumentDetailWrapper .content-area .meta-info{
    height: 50%;
    padding-left: 5px;
}

.FileDocumentDetailWrapper .content-area .file-name {
    padding-top: 2px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #333333;
}

.FileDocumentDetailWrapper .content-area .file-size {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #757575;
}

.FileDocumentDetailWrapper .content-area .status-wrapper {
    height: 50%;
    display: flex;
    align-items: center;
}

.FileDocumentDetailWrapper .content-area .status-wrapper .status-chip {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 20px;
    gap: 6px;
    width: fit-content;
    height: 22px;
    border-radius: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    user-select: none;
}
.FileDocumentDetailWrapper .content-area .status-wrapper .status-chip.pending {
    background: #eaecf0;
    color: #757575;
}
.FileDocumentDetailWrapper .content-area .status-wrapper .status-chip.rejected {
    background: #FDF3F2;
    color: #B32306;
}
.FileDocumentDetailWrapper .content-area .status-wrapper .status-chip.approved {
    background: #EDF7F2;
    color: #07794C;
}
.FileDocumentDetailWrapper .content-area .status-wrapper .status-chip.changeRequest {
    background: #FFF3CC;
    color: #997400;
}
.FileDocumentDetailWrapper .content-area .status-wrapper .status-chip.changed {
    background: #EDF7F2;
    color: #07794C;
}
.FileDocumentDetailWrapper .action-wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
}
.FileDocumentDetailWrapper .action-wrapper .action-menu{
    position: absolute;
    left: -10px;
    width: 186px;
    height: 80px;
    background: #FFFFFF;
    border: 1px solid #F5F5F5;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    display: none;
}
.FileDocumentDetailWrapper .action-wrapper .action-trigger{
    height: 40px;
    width: 10px;
}
.FileDocumentDetailWrapper .action-wrapper .action-trigger:hover .action-menu{
    display: flex;
    flex-direction: column;
}
.FileDocumentDetailWrapper .action-wrapper .action-menu .action-item {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #545454;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 10px;
    border-radius: 8px;
    cursor: pointer;
}

.FileDocumentDetailWrapper .action-wrapper .action-menu .action-item:hover {
    background-color: #f1f1f1;
}

