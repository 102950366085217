/* Previously Uploaded File Component */
.pu-file-wrapper {
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  align-items: center;
  width: 100%;
  max-width: 450px;
  height: 60px;
  border-radius: 8px;
  border: 1px solid #cccccc;
  padding: 0px 10px;
  cursor: pointer;
}

.pu-file-wrapper.active {
  border: 1px solid #FFC200;
}

.pu-file-wrapper:hover {
  box-shadow: 0 0 8px rgba(0,0,0,0.05);
}

.pu-file-wrapper .icon {
  width: 36px;
  height: 36px;
}

.pu-file-wrapper .file-name {
  user-select: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #757575;
  padding-left: 14px;
}

.pu-file-wrapper .view-icon {
  cursor: pointer;
  align-self: flex-end;
  padding: 10px;
}
